import { ActivatedRoute } from '@angular/router';

export function traverseRouterTree(
  node: ActivatedRoute | null,
  callback?: (node: ActivatedRoute) => void
) {
  if (!node) {
    return;
  }

  if (callback) {
    callback(node);
  }

  traverseRouterTree(node.firstChild, callback);
}
