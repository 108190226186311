import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AuthService } from '@auth0/auth0-angular';
import { BlocksModule, MenuRiService, PermissionsService } from '@cloudmed/ui';
import { InsightsService } from '../../services/insights/insights.service';

@Component({
  selector: 'ri-resolution-ui-app-header',
  standalone: true,
  imports: [BlocksModule],
  templateUrl: './app-header.component.html',
  styleUrl: './app-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
  constructor(
    private readonly auth: AuthService,
    private readonly menuService: MenuRiService,
    private readonly permissionsService: PermissionsService,
    private readonly appInsights: InsightsService
  ) {
    this.setupAuth0Items();
  }

  logout(): void {
    this.auth.logout({
      logoutParams: { returnTo: location.origin, federated: false },
    });
  }

  private setupAuth0Items() {
    this.auth.user$.pipe(takeUntilDestroyed()).subscribe((user) => {
      if (user && user.email) {
        this.appInsights.setAuthenticatedUserContext(user.email);
      } else {
        this.appInsights.clearAuthenticatedUserContext();
      }

      this.menuService.loadMenuItems();
      this.permissionsService.loadPermissions();
    });
  }
}
