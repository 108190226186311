import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GlobalToastComponent } from './shared/components/global-toast/global-toast.component';
import { AppHeaderComponent } from './shared/components/app-header/app-header.component';
import { RouteObserver } from './shared/services/route-observer/route-observer.service';
import { TasksWorkerTodoListEffect } from './routes/tasks-worker/shared/effects/tasks-worker-todo-list.effect';
import { TasksWorkerCompletedListEffect } from './routes/tasks-worker/shared/effects/tasks-worker-completed-list.effect';
import { registerActiveTaskUpdater } from './routes/tasks-worker/shared/effects/sidebar.effect';
import { InsightsService } from './shared/services/insights/insights.service';
import { AllTasksEffect } from './routes/tasks/shared/effects/all-tasks.effect';

@Component({
  standalone: true,
  imports: [RouterOutlet, GlobalToastComponent, AppHeaderComponent],
  selector: 'ri-resolution-ui-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly injector: Injector,
    private readonly routeObserver: RouteObserver,
    private readonly tasksWorkerTodoListEffect: TasksWorkerTodoListEffect,
    private readonly tasksWorkerCompletedListEffect: TasksWorkerCompletedListEffect,
    private readonly allTasksEffect: AllTasksEffect,
    // InsightsService should be instantiated even it is not used directly
    private readonly insightsService: InsightsService
  ) {
    // Load tasks
    this.tasksWorkerTodoListEffect.loadTasks();
    this.tasksWorkerCompletedListEffect.loadTasks();
    this.allTasksEffect.loadTasks();

    // update activeTaskId state on url change.
    registerActiveTaskUpdater(this.routeObserver, this.injector);

    // start listening angular router navigations
    this.routeObserver.observe();
  }
}
