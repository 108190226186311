import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { traverseRouterTree } from '../../utils/router.utils';
import { Observable, filter, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteObserver {
  constructor(private readonly router: Router) {}

  private readonly subscribers = new Map<
    Route['path'],
    Array<{
      callback: (node: ActivatedRoute, injector: Injector) => void;
      injector: Injector;
    }>
  >();

  private observed = false;

  register(options: {
    path: string;
    injector: Injector;
    callback: (node: ActivatedRoute, injector: Injector) => void;
    destroy?: Observable<void>;
  }): {
    unregister: () => void;
  } {
    if (!this.subscribers.has(options.path)) {
      this.subscribers.set(options.path, []);
    }

    const pathSubscribers = this.subscribers.get(options.path)!;

    pathSubscribers.push({
      callback: options.callback,
      injector: options.injector,
    });

    const removeCallbackFromSubscribers = () => {
      const idx = pathSubscribers.findIndex(
        (subscriber) => subscriber.callback === options.callback
      );
      pathSubscribers.splice(idx, 1);
    };

    if (options.destroy) {
      options.destroy.pipe(take(1)).subscribe(() => {
        removeCallbackFromSubscribers();
      });
    }

    return {
      unregister: () => {
        removeCallbackFromSubscribers();
      },
    };
  }

  observe(): { stopObserver: () => void } {
    if (this.observed) {
      throw new Error('Router observer is already run');
    }

    this.observed = true;

    const subscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.run();
      });

    return {
      stopObserver: () => {
        subscription.unsubscribe();
        this.observed = false;
      },
    };
  }

  private run() {
    let assembledPath = '';
    traverseRouterTree(this.router.routerState.root, (node: ActivatedRoute) => {
      if (!node.routeConfig?.path) {
        return;
      }
      assembledPath += `/${node.routeConfig.path}`;
      if (this.subscribers.has(assembledPath)) {
        const pathSubscribers = this.subscribers.get(assembledPath)!;
        for (const subscriber of pathSubscribers) {
          subscriber.callback(node, subscriber.injector);
        }
      }
    });
  }
}
